const fn = {
  init: () => {
    fn.fitVids()
  },

  fitVids: () => {

    var fitVids = document.querySelectorAll('.ss-htmleditorfield-file.embed')

    if(fitVids.length) {

      for(var i=0;i<fitVids.length;i++) {
        let fitVid = fitVids[i];
        fitVid.classList.add('video-container')
      }

    }
  }
}

export default {
  can: () => true,
  run: fn.init
}
