import { on } from 'delegated-events'
import debounce from 'lodash/debounce'
import Pristine from 'pristinejs'
import scrollTo from '../common/scrollTo'
import { slideDown } from '../common/slideToggle'
import { formatMoney } from 'accounting'

const SELECTORS = Selectors({
  ticketsWrapper: 'event-ticket-form-wrapper',
  ticketsForm: 'event-tickets-form',
  formTrigger: 'event-ticket-form-trigger',
  formAnchor: '[data-form-anchor]',
  formErrorMessage: 'form-error-message',
  nameField: 'name-field',
  numTickets: 'input[name=NumTickets]',
  ticketTotal: 'event-tickets-number-total',
  ticketHolders: 'event-ticket-holders',
  donationField: 'input[name=DonationAmount]',
  totalField: 'event-tickets-fields__total span',
  submitButton: 'event-tickets-form [type=submit]',
  stripeCardElement: 'event-tickets-card-element'
})

const props = {
  $ticketsWrapper: document.querySelector(SELECTORS.asClass('ticketsWrapper')),
  $form: document.querySelector(SELECTORS.asClass('ticketsForm')),
  $numTickets: document.querySelector(SELECTORS.numTickets),
  $ticketTotal: document.querySelector(SELECTORS.asClass('ticketTotal')),
  $ticketHolders: document.querySelector(SELECTORS.asClass('ticketHolders')),
  $donationField: document.querySelector(SELECTORS.donationField),
  $totalField: document.querySelector(SELECTORS.asClass('totalField')),
  $submitButton: document.querySelector(SELECTORS.asClass('submitButton')),
  $cardElement: document.getElementById(SELECTORS.stripeCardElement),

  pristineConfig: {
    classTo: 'field',
    errorClass: 'has-error',
    successClass: 'has-success',
    errorTextParent: 'field',
    errorTextTag: 'div',
    errorTextClass: 'text-help'
  }
}

const state = {
  stripe: null,
  stripeCard: null,
  pristine: null,
  didAutofillTicketHolder: false
}

const fn = {
  init: () => {
    if (props.$cardElement) {
      state.stripe = window.Stripe(props.$form.dataset.stripePubKey)
      fn.setupStripeCard()
    }

    fn.setupValidation()

    props.$numTickets.addEventListener('keydown', e => {
      if (e.keyCode === 48 && e.target.value.length === 0) {
        e.preventDefault()

        return false;
      }
    })

    if (props.$ticketHolders) {
      fn.updateTicketHolders()

      props.$numTickets.addEventListener('keyup', fn.updateTicketHolders)

      on('change', `${SELECTORS.asClass('nameField')}`, () => {
        const firstName = props.$form.querySelector('input[name=FirstName]').value
        const lastName = props.$form.querySelector('input[name=LastName]').value

        if (firstName && lastName && !state.didAutofillTicketHolder) {
          const $ticketHolderFirstName = props.$ticketHolders.querySelectorAll('input[name="TicketHolderFirstName[]"]')[0]
          const $ticketHolderLastName = props.$ticketHolders.querySelectorAll('input[name="TicketHolderLastName[]"]')[0]

          $ticketHolderFirstName.value = firstName
          $ticketHolderLastName.value = lastName
          state.didAutofillTicketHolder = true
        }
      })
    } else {
      props.$numTickets.addEventListener('keyup', fn.updateTotal)
    }

    props.$form.addEventListener('submit', fn.handleSubmit)

    props.$donationField.addEventListener('keyup', fn.updateTotal)

    fn.updateTotal()

    on('click', `${SELECTORS.asClass('formTrigger')}`, () => {
      slideDown(props.$ticketsWrapper)
      scrollTo(props.$ticketsWrapper, -30)
    })
  },

  updateTicketHolders: () => {
    const numTickets = parseInt(props.$numTickets.value)
    const numTicketHolders = props.$ticketHolders.children.length

    if (state.pristine.validate(props.$numTickets)) {
      if (!isNaN(numTickets) && numTickets > 0) {
        if (numTickets > numTicketHolders) {
          for (let i = numTicketHolders; i < numTickets; i++) {
            const ticketHolderField = `
              <dl class="event-tickets-fields__holders">
                <dt>Ticket Holder Name ${i + 1}</dt>
                <dd>
                  <div class="field">
                    <input type="text" name="TicketHolderFirstName[]" placeholder="First Name" required>
                  </div>

                  <div class="field">
                    <input type="text" name="TicketHolderLastName[]" placeholder="Last Name" required>
                  </div>
                </dd>
              </dl>
            `

            props.$ticketHolders.appendChild(document.createRange().createContextualFragment(ticketHolderField))
          }
        }

        if (numTickets < numTicketHolders) {
          for (let i = numTicketHolders; i > numTickets; i--) {
            props.$ticketHolders.children[i - 1].remove()
          }
        }
      }
    }

    fn.updateTotal()
    fn.setupValidation()
  },

  updateTotal: () => {
    const ticketCost = props.$form.dataset.ticketCost
    const numTickets = parseInt(props.$numTickets.value)
    const donationAmount = Number(props.$donationField.value.replace('$', ''))
    const ticketTotal = ticketCost * numTickets

    if (state.pristine.validate(props.$numTickets)) {
      props.$ticketTotal.textContent = formatMoney(ticketTotal)
    } else {
      props.$ticketTotal.textContent = '-'
    }

    const total = ticketTotal + donationAmount

    if (state.pristine.validate(props.$donationField)) {
      props.$totalField.textContent = formatMoney(total)
    } else {
      props.$totalField.textContent = '-'
    }
  },

  setupValidation: () => {
    state.pristine = new Pristine(props.$form, props.pristineConfig)

    // Revalidate easydropdown fields on change
    on('change',
      `${SELECTORS.asClass('ticketsForm')} select`,
      e => state.pristine.validate(e.target)
    )
  },

  handleSubmit: (e) => {
    e.preventDefault()

    const valid = state.pristine.validate()

    if (!valid) {
      const $firstError = props.$form.querySelector('.field.has-error')

      if ($firstError) {
        scrollTo($firstError, 150, () => $firstError.querySelector('input') && $firstError.querySelector('input').focus())
      }

      return false
    }

    // Handle Stripe
    props.$submitButton.disabled = true

    state.stripe.createToken(state.stripeCard).then((result) => {
      if (result.error) {
        const errorElement = document.getElementById('event-tickets-card-errors')
        errorElement.textContent = result.error.message
        props.$submitButton.disabled = false
      } else {
        fn.stripeTokenHandler(result.token)
      }
    })

    return false
  },

  stripeTokenHandler: (token) => {
    const hiddenInput = document.createElement('input')
    hiddenInput.setAttribute('type', 'hidden')
    hiddenInput.setAttribute('name', 'stripeToken')
    hiddenInput.setAttribute('value', token.id)
    props.$form.appendChild(hiddenInput)

    const $errorMessage = props.$form.querySelector(SELECTORS.asClass('formErrorMessage'))

    ajax.post(props.$form.action, new FormData(props.$form))
      .then(resp => {
        if (resp.data.success) {
          props.$form.innerHTML = `<div class="form-success-message">${resp.data.message}</div>`

          scrollTo(props.$ticketsWrapper)
        } else {
          $errorMessage.innerHTML = resp.data.message
          $errorMessage.style.display = 'block'
        }
      })
      .catch(() => {
        $errorMessage.innerHTML = 'Sorry, there was a problem with your submission'
        $errorMessage.style.display = 'block'
      })
      .finally(() => {
        props.$submitButton.disabled = false
      })
  },

  setupStripeCard: () => {
    const style = {
      base: {
        backgroundColor: 'white',
        fontSize: '16px',
        fontFamily: 'motiva-sans, sans-serif',
        color: '#2c2c2c',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#bdbdbd'
        }
      },
      invalid: {
        color: '#b9112a'
      }
    }

    const card = state.stripe.elements().create('card', { style })
    card.mount(SELECTORS.asId('stripeCardElement'))
    state.stripeCard = card

    card.addEventListener('change', ({error}) => {
      const displayError = document.getElementById('event-tickets-card-errors')
      if (error) {
        displayError.textContent = error.message
      } else {
        displayError.textContent = ''
      }
    })

    window.addEventListener('resize', debounce(fn.updateStripeCard, 250))
    fn.updateStripeCard()
  },

  updateStripeCard: () => {
    let fontSize = '16px'

    if (window.matchMedia('(min-width: 1200px)').matches) {
      fontSize = '18px'
    }

    const style = {
      base: {
        fontSize
      }
    }

    state.stripeCard.update({ style })
  }
}

export default {
  can: () => props.$form,
  run: fn.init
}
