import { on } from 'delegated-events'
import 'lightgallery.js/dist/js/lightgallery.js'
import 'lg-thumbnail.js/dist/lg-thumbnail.js'
import 'lightgallery.js/dist/css/lightgallery.css'

const lightGallery = window.lightGallery

const SELECTORS = Selectors({
  galleries: '[data-photo-galleries]',
  gallery: '[data-gallery]',
  galleryTrigger: 'photo-gallery__open',
  galleryPhoto: 'photogal-photo'
})

const props = {
  $galleries: document.querySelectorAll(SELECTORS.galleries),
  $photoGalleries: document.querySelectorAll(SELECTORS.gallery)
}

const fn = {
  init: () => {

    props.$photoGalleries.forEach(el => {
      lightGallery(el, {
        selector: '.photogal-photo',
        thumbnail: true,
        download: false,
        galleryId: el.id,
        getCaptionFromTitleOrAlt: true
      })
    })

    fn.bindEvents()
  },

  bindEvents: () => {
    on('click', SELECTORS.asClass('galleryTrigger'), e => {
      e.preventDefault()
      const $gallery = e.currentTarget.nextElementSibling.nextElementSibling
      $gallery.querySelectorAll('.photogal-photo')[0].click()
    })
  }
}

export default {
  can: () => props.$galleries.length,
  run: fn.init
}
