import { on } from 'delegated-events'
import 'lightgallery.js/dist/js/lightgallery.js'
import 'lg-thumbnail.js/dist/lg-thumbnail.js'
import 'lightgallery.js/dist/css/lightgallery.css'

const lightGallery = window.lightGallery

const SELECTORS = Selectors({
  gallery: '[data-photo-gallery]',
  galleryTrigger: 'photo-gallery__trigger',
  galleryPhoto: 'photo-gallery__photo'
})

const props = {
  $galleries: document.querySelectorAll(SELECTORS.gallery)
}

const fn = {
  init: () => {
    on('click', SELECTORS.asClass('galleryTrigger'), e => {
        e.preventDefault()

        const gallery = e.target.closest('[data-photo-gallery]')
        const startIndex = e.currentTarget.dataset.galleryIndex || 1
        const galleryData = JSON.parse(gallery.dataset.galleryList)

        lightGallery(gallery, {
          index: parseInt(startIndex - 1),
          dynamic: true,
          dynamicEl: galleryData,
          thumbnail: true,
          download: false
        })

        gallery.addEventListener('onCloseAfter', () => {
          const uid = gallery.getAttribute('lg-uid')

          if (Object.prototype.hasOwnProperty.call(window.lgData, uid)) {
            window.lgData[uid].destroy(true)
          }
        }, { once: true })
      })
  },
}

export default {
  can: () => props.$galleries.length,
  run: fn.init
}
