import { on } from 'delegated-events'
import { slideUp, slideDown } from '../common/slideToggle'

const topBar = document.querySelector('.site-announcement')
const siteHeader = document.querySelector('.site-header')

const fn = {
  init: () => {
    window.addEventListener('resize', fn.setHeight)
    fn.setHeight()

    on('click', '.announcement-toggle-hide', () => {
      siteHeader.style.opacity = 0

      slideUp(topBar).then(() => {
        siteHeader.style.opacity = 1
        document.documentElement.classList.add('announcement-is-hidden')
        fn.setHeight()
      })
    })

    on('click', '.announcement-toggle-show', () => {
        siteHeader.style.opacity = 0
      document.documentElement.classList.remove('announcement-is-hidden')

      slideDown(topBar).then(() => {
        siteHeader.style.opacity = 1
        fn.setHeight()
      })
    })
  },

  setHeight: () => {
    document.documentElement.style.setProperty('--site-announcement-height', `${topBar.offsetHeight}px`)
  },
}

export default {
  can: () => topBar,
  run: fn.init,
}
