import { on } from 'delegated-events'
import debounce from 'lodash/debounce'
import Pristine from 'pristinejs'
import scrollTo from '../common/scrollTo'
import { slideDown } from '../common/slideToggle'

const SELECTORS = Selectors({
  sponsorshipWrapper: 'event-sponsorship-form-wrapper',
  sponsorshipForm: 'event-sponsorship-form',
  formTrigger: 'event-sponsorship-form-trigger',
  formAnchor: '[data-form-anchor]',
  formErrorMessage: 'form-error-message',
  payOption: 'input[name=PayOption]',
  submitButton: 'event-sponsorship-form [type=submit]',
  stripeCardElement: 'event-sponsorship-card-element'
})

const props = {
  $sponsorshipWrapper: document.querySelector(SELECTORS.asClass('sponsorshipWrapper')),
  $form: document.querySelector(SELECTORS.asClass('sponsorshipForm')),
  $submitButton: document.querySelector(SELECTORS.asClass('submitButton')),
  $cardElement: document.getElementById(SELECTORS.stripeCardElement),

  pristineConfig: {
    classTo: 'field',
    errorClass: 'has-error',
    successClass: 'has-success',
    errorTextParent: 'field',
    errorTextTag: 'div',
    errorTextClass: 'text-help'
  }
}

const state = {
  stripe: null,
  stripeCard: null,
  pristine: null
}

const fn = {
  init: () => {
    if (props.$cardElement) {
      state.stripe = window.Stripe(props.$form.dataset.stripePubKey)
      fn.setupStripeCard()
    }

    fn.setupValidation()

    props.$form.addEventListener('submit', fn.handleSubmit)

    on('click', `${SELECTORS.asClass('formTrigger')}`, () => {
      slideDown(props.$sponsorshipWrapper)

      if (window.matchMedia('(min-width: 768px)').matches) {
        scrollTo(props.$sponsorshipWrapper, window.innerWidth * -0.1)
      } else {
        scrollTo(props.$sponsorshipWrapper)
      }
    })
  },

  setupValidation: () => {
    state.pristine = new Pristine(props.$form, props.pristineConfig)

    // Revalidate easydropdown fields on change
    on('change',
      `${SELECTORS.asClass('ticketsForm')} select`,
      e => state.pristine.validate(e.target)
    )
  },

  handleSubmit: (e) => {
    e.preventDefault()

    const valid = state.pristine.validate()

    if (!valid) {
      const $firstError = props.$form.querySelector('.field.has-error')

      if ($firstError) {
        scrollTo($firstError, 150, () => $firstError.querySelector('input') && $firstError.querySelector('input').focus())
      }

      return false
    }

    // Figure out if we need Stripe
    if (document.querySelector(`${SELECTORS.payOption}:checked`).value === 'Check') {
      fn.doSubmit()
    } else {
      // Handle Stripe
      props.$submitButton.disabled = true

      state.stripe.createToken(state.stripeCard).then((result) => {
        if (result.error) {
          const errorElement = document.getElementById('event-tickets-card-errors')
          errorElement.textContent = result.error.message
          props.$submitButton.disabled = false
        } else {
          fn.stripeTokenHandler(result.token)
        }
      })

    }
    return false
  },

  doSubmit: () => {
    const $errorMessage = props.$form.querySelector(SELECTORS.asClass('formErrorMessage'))

    ajax.post(props.$form.action, new FormData(props.$form))
      .then(resp => {
        if (resp.data.success) {
          props.$form.innerHTML = `<div class="form-success-message">${resp.data.message}</div>`

          scrollTo(props.$sponsorshipWrapper)
        } else {
          $errorMessage.innerHTML = resp.data.message
          $errorMessage.style.display = 'block'
        }
      })
      .catch(() => {
        $errorMessage.innerHTML = 'Sorry, there was a problem with your submission'
        $errorMessage.style.display = 'block'
      })
      .finally(() => {
        props.$submitButton.disabled = false
      })
  },

  stripeTokenHandler: (token) => {
    const hiddenInput = document.createElement('input')
    hiddenInput.setAttribute('type', 'hidden')
    hiddenInput.setAttribute('name', 'stripeToken')
    hiddenInput.setAttribute('value', token.id)
    props.$form.appendChild(hiddenInput)

    fn.doSubmit()
  },

  setupStripeCard: () => {
    const style = {
      base: {
        backgroundColor: 'white',
        fontSize: '16px',
        fontFamily: 'motiva-sans, sans-serif',
        color: '#2c2c2c',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#bdbdbd'
        }
      },
      invalid: {
        color: '#b9112a'
      }
    }

    const card = state.stripe.elements().create('card', { style })
    card.mount(SELECTORS.asId('stripeCardElement'))
    state.stripeCard = card

    card.addEventListener('change', ({error}) => {
      const displayError = document.getElementById('event-tickets-card-errors')
      if (error) {
        displayError.textContent = error.message
      } else {
        displayError.textContent = ''
      }
    })

    window.addEventListener('resize', debounce(fn.updateStripeCard, 250))
    fn.updateStripeCard()
  },

  updateStripeCard: () => {
    let fontSize = '16px'

    if (window.matchMedia('(min-width: 1200px)').matches) {
      fontSize = '18px'
    }

    const style = {
      base: {
        fontSize
      }
    }

    state.stripeCard.update({ style })
  }
}

export default {
  can: () => props.$form,
  run: fn.init
}
