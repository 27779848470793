import Swiper, { Autoplay, EffectFade, Navigation, Thumbs } from 'swiper'

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/thumbs/thumbs.min.css'

Swiper.use([Autoplay, EffectFade, Navigation, Thumbs])

document.querySelectorAll('.element-testimonialslideralt').forEach((el) => {
  const mainContiner = el.querySelector('.testimonials-alt-slider')
  const thumbContainer = el.querySelector('.testimonials-alt-thumb-slider')
  const numSlides = mainContiner.querySelectorAll('.swiper-slide').length

  if (numSlides > 1) {
    const thumbSwiper = new Swiper(thumbContainer.querySelector('.swiper'), {
      slidesPerView: 'auto',
      spaceBetween: 0,
      preloadImages: false,
      watchSlidesProgress: true,
      threshold: 20,
    })

    new Swiper(mainContiner.querySelector('.swiper'), {
      slidesPerView: 1,
      speed: 1000,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      preloadImages: false,
      loop: true,
      loopedSlides: numSlides,
      autoplay: {
        delay: 6000,
      },
      navigation: {
        nextEl: el.querySelector('.slider-control-next'),
        prevEl: el.querySelector('.slider-control-prev'),
      },
      thumbs: {
        multipleActiveThumbs: false,
        swiper: thumbSwiper,
      },
    })
  }
})
