import { on } from 'delegated-events'

const SELECTORS = Selectors({
  element: 'element-waystogive',
  slider: 'wtg-slider',
  slide: 'wtg-slide',
  sliderPrev: 'wtg-arrow--prev',
  sliderNext: 'wtg-arrow--next',
  sliderDots: 'wtg-dots',
  selected: 'is-selected',
  reversing: 'is-reversing',
  ref: 'is-ref',
  set: 'is-set'
})

const props = {
  $slider: document.querySelector(SELECTORS.asClass('slider')),
  $slides: document.querySelectorAll(SELECTORS.asClass('slide')),
  $prev: document.querySelector(SELECTORS.asClass('sliderPrev')),
  $next: document.querySelector(SELECTORS.asClass('sliderNext')),
  $dots: document.querySelector(SELECTORS.asClass('sliderDots')),
  totalItems: 0,
  currentIndex: 0
}

const fn = {
  init: () => {
    props.totalItems = props.$slides.length

    fn.bindEvents()
  },

  bindEvents: () => {
    if (props.$prev) {
      props.$prev.addEventListener('click', () => {
        let newIndex = props.currentIndex - 1

        if (newIndex < 0) {
          newIndex = props.totalItems - 1
        }

        fn.goTo(newIndex)
      })
    }

    if (props.$next) {
      props.$next.addEventListener('click', () => {
        let newIndex = props.currentIndex + 1

        if (newIndex >= props.totalItems) {
          newIndex = 0
        }

        fn.goTo(newIndex)
      })
    }

    // Dataset indices are 1 based
    on('click', `${SELECTORS.asClass('sliderDots')} button`, e => {
      parseInt(fn.goTo(e.target.dataset.index - 1))
    })

    on('click', `${SELECTORS.asClass('slide')}:not(${SELECTORS.asClass('selected')})`, e => {
      parseInt(fn.goTo(e.target.closest(SELECTORS.asClass('slide')).dataset.index - 1))
    })
  },

  goTo: (index) => {
    const isLessThan = index < props.currentIndex
    const isFirstToLast = (index === props.totalItems - 1) && props.currentIndex === 0
    const isLastToFirst = index === 0 && (props.currentIndex === props.totalItems - 1)

    if ((isLessThan || isFirstToLast) && !isLastToFirst) {
      props.$slider.classList.add(SELECTORS.reversing)
    } else {
      props.$slider.classList.remove(SELECTORS.reversing)
    }

    props.currentIndex = index
    props.$slider.querySelector(SELECTORS.asClass('ref')).classList.remove(SELECTORS.ref)

    let $slide = fn.getPrev(props.$slides.item(index))

    $slide.classList.add(SELECTORS.ref)
    $slide.style.order = 1

    props.$slider.querySelector(SELECTORS.asClass('selected')).classList.remove(SELECTORS.selected)
    fn.getNext($slide).classList.add(SELECTORS.selected)

    for (const i of Array(props.totalItems - 1).fill().map((_, i) => 2 + i)) {
      $slide = fn.getNext($slide)
      $slide.style.order = i
    }

    props.$slider.classList.remove(SELECTORS.set)

    setTimeout(() => {
      props.$slider.classList.add(SELECTORS.set)
    }, 10)

    props.$dots.querySelector(SELECTORS.asClass('selected')).classList.remove(SELECTORS.selected)
    props.$dots.querySelectorAll('button').item(props.currentIndex).classList.add(SELECTORS.selected)
  },

  getNext: ($el) => {
    if ($el.nextElementSibling) {
      return $el.nextElementSibling
    }

    return props.$slides.item(0)
  },

  getPrev: ($el) => {
    if ($el.previousElementSibling) {
      return $el.previousElementSibling
    }

    return props.$slides.item(props.totalItems - 1)
  }
}

export default {
  can: () => props.$slider,
  run: fn.init
}
